import React, { LabelHTMLAttributes } from "react";

function Label({
  children,
  optional,
  ...props
}: LabelHTMLAttributes<HTMLLabelElement> & { optional?: boolean }) {
  return (
    <label {...props} className="font-semibold text-sm">
      {children}
      {optional && <span className="text-gray-500"> (opcional)</span>}
    </label>
  );
}

export default Label;
