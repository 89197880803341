import { faLock } from "@fortawesome/free-solid-svg-icons";
import { NextPageContext } from "next";
import { getCsrfToken } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import Button from "../components/forms/Button";
import Label from "../components/forms/Label";
import Input from "../components/forms/react-hook-form/Input";
import paths from "../config/paths";

import logo from "../public/cru-logo.png";
import PasswordInput from "../components/forms/PasswordInput";

function Login({ csrfToken }: { csrfToken: string }): JSX.Element {
  const router = useRouter();

  // Get the query parameter from the URL
  const { error } = router.query;
  return (
    <div className="min-h-screen flex flex-col md:justify-center">
      <div className="grow md:grow-0 md:max-w-lg w-full px-4 sm:px-10 mx-auto bg-white md:border md:shadow-md md:rounded-lg">
        <div>
          <Link href={paths.home}>
            <Image
              className="h-14 w-auto mx-auto my-8"
              src={logo}
              alt="Cruz roja Uruguaya"
            />
          </Link>
          <h2 className="text-4xl font-bold border-b-4 border-primary inline-block">
            Acceder
          </h2>

          {error && (
            <div className="alert alert-error shadow-lg mt-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current flex-shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>
                  {error === "CredentialsSignin" &&
                    "Email o contraseña incorrecta"}
                </span>
                <span>
                  {error === "not-person" &&
                    "Estás conectado con tu cuenta de administrador. Ingresa con tu cuenta personal"}
                </span>
              </div>
            </div>
          )}

          <form
            method="post"
            action="/api/auth/callback/credentials"
            className="mt-8"
          >
            <input name="csrfToken" type="hidden" defaultValue={csrfToken} />

            <div className="mb-4">
              <Label htmlFor="email">Correo electrónico</Label>
              <input
                name="email"
                className="relative w-full sm:text-sm placeholder-gray-200 px-4 py-3 text-left  rounded-lg border-neutral-300 border focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary text-gray-700 focus:text-gray-900"
              />
            </div>

            <div className="mb-4">
              <Label htmlFor="password">Contraseña</Label>
              <PasswordInput
                name="password"
                className="relative w-full sm:text-sm placeholder-gray-200 px-4 py-3 text-left  rounded-lg border-neutral-300 border focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary text-gray-700 focus:text-gray-900"
              />
            </div>
            <div className="text-center my-8">
              <Button icon={faLock} className="w-full" type="submit">
                Ingresar
              </Button>
              {/* <Link href="#" className="link mt-3">
                Olvidé mi contraseña
              </Link> */}
            </div>
          </form>
        </div>
        <div className="text-center">
          <h2 className="font-bold">¿Eres nuevo y quieres registrarte?</h2>
          <Link href={paths.register}>
            <Button className="w-full" color="light">
              Registro de persona voluntaria
            </Button>
          </Link>
          <div className="py-4">
            <Link href="#" className="text-xs link">
              Condiciones de manejo de datos personales
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

export async function getServerSideProps(context: NextPageContext) {
  return {
    props: {
      csrfToken: await getCsrfToken(context),
    },
  };
}
